import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const BuildingIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M6.75 6.5C7.16421 6.5 7.5 6.16421 7.5 5.75C7.5 5.33579 7.16421 5 6.75 5C6.33579 5 6 5.33579 6 5.75C6 6.16421 6.33579 6.5 6.75 6.5Z" />
        <path d="M7.5 8.75C7.5 9.16421 7.16421 9.5 6.75 9.5C6.33579 9.5 6 9.16421 6 8.75C6 8.33579 6.33579 8 6.75 8C7.16421 8 7.5 8.33579 7.5 8.75Z" />
        <path d="M6.75 12.5C7.16421 12.5 7.5 12.1642 7.5 11.75C7.5 11.3358 7.16421 11 6.75 11C6.33579 11 6 11.3358 6 11.75C6 12.1642 6.33579 12.5 6.75 12.5Z" />
        <path d="M10.5 5.75C10.5 6.16421 10.1642 6.5 9.75 6.5C9.33579 6.5 9 6.16421 9 5.75C9 5.33579 9.33579 5 9.75 5C10.1642 5 10.5 5.33579 10.5 5.75Z" />
        <path d="M9.75 9.5C10.1642 9.5 10.5 9.16421 10.5 8.75C10.5 8.33579 10.1642 8 9.75 8C9.33579 8 9 8.33579 9 8.75C9 9.16421 9.33579 9.5 9.75 9.5Z" />
        <path d="M10.5 11.75C10.5 12.1642 10.1642 12.5 9.75 12.5C9.33579 12.5 9 12.1642 9 11.75C9 11.3358 9.33579 11 9.75 11C10.1642 11 10.5 11.3358 10.5 11.75Z" />
        <path d="M12.75 12.5C13.1642 12.5 13.5 12.1642 13.5 11.75C13.5 11.3358 13.1642 11 12.75 11C12.3358 11 12 11.3358 12 11.75C12 12.1642 12.3358 12.5 12.75 12.5Z" />
        <path d="M4.5 18C4.22386 18 4 17.7761 4 17.5V3.5C4 2.67157 4.67157 2 5.5 2H11.5C12.3284 2 13 2.67157 13 3.5V8H14.5C15.3284 8 16 8.67157 16 9.5V17.5C16 17.7761 15.7761 18 15.5 18H4.5ZM5 3.5V17H7V14.5C7 14.2239 7.22386 14 7.5 14H12.5C12.7761 14 13 14.2239 13 14.5V17H15V9.5C15 9.22386 14.7761 9 14.5 9H12.5C12.2239 9 12 8.77614 12 8.5V3.5C12 3.22386 11.7761 3 11.5 3H5.5C5.22386 3 5 3.22386 5 3.5ZM12 15H10.5V17H12V15ZM9.5 15H8V17H9.5V15Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M4 3.5C4 2.67157 4.67157 2 5.5 2H11.5C12.3284 2 13 2.67157 13 3.5V8H14.5C15.3284 8 16 8.67157 16 9.5V17.5C16 17.7761 15.7761 18 15.5 18H13V14.5C13 14.2239 12.7761 14 12.5 14H7.5C7.22386 14 7 14.2239 7 14.5V18H4.5C4.22386 18 4 17.7761 4 17.5V3.5ZM6.75 6.5C7.16421 6.5 7.5 6.16421 7.5 5.75C7.5 5.33579 7.16421 5 6.75 5C6.33579 5 6 5.33579 6 5.75C6 6.16421 6.33579 6.5 6.75 6.5ZM7.5 8.75C7.5 8.33579 7.16421 8 6.75 8C6.33579 8 6 8.33579 6 8.75C6 9.16421 6.33579 9.5 6.75 9.5C7.16421 9.5 7.5 9.16421 7.5 8.75ZM6.75 12.5C7.16421 12.5 7.5 12.1642 7.5 11.75C7.5 11.3358 7.16421 11 6.75 11C6.33579 11 6 11.3358 6 11.75C6 12.1642 6.33579 12.5 6.75 12.5ZM10.5 5.75C10.5 5.33579 10.1642 5 9.75 5C9.33579 5 9 5.33579 9 5.75C9 6.16421 9.33579 6.5 9.75 6.5C10.1642 6.5 10.5 6.16421 10.5 5.75ZM9.75 9.5C10.1642 9.5 10.5 9.16421 10.5 8.75C10.5 8.33579 10.1642 8 9.75 8C9.33579 8 9 8.33579 9 8.75C9 9.16421 9.33579 9.5 9.75 9.5ZM10.5 11.75C10.5 11.3358 10.1642 11 9.75 11C9.33579 11 9 11.3358 9 11.75C9 12.1642 9.33579 12.5 9.75 12.5C10.1642 12.5 10.5 12.1642 10.5 11.75ZM12.75 12.5C13.1642 12.5 13.5 12.1642 13.5 11.75C13.5 11.3358 13.1642 11 12.75 11C12.3358 11 12 11.3358 12 11.75C12 12.1642 12.3358 12.5 12.75 12.5Z" />
        <path d="M12 15V18H10.5V15H12Z" />
        <path d="M9.5 15H8V18H9.5V15Z" />
      </g>
    </svg>
  ),
  displayName: 'BuildingIcon',
});
