import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const TextBulletListTreeIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M3 6.5C3.55228 6.5 4 6.05228 4 5.5C4 4.94772 3.55228 4.5 3 4.5C2.44772 4.5 2 4.94772 2 5.5C2 6.05228 2.44772 6.5 3 6.5Z" />
        <path d="M6 5.5C6 5.22386 6.22386 5 6.5 5H17.5C17.7761 5 18 5.22386 18 5.5C18 5.77614 17.7761 6 17.5 6H6.5C6.22386 6 6 5.77614 6 5.5Z" />
        <path d="M6 9.5C6 9.22386 6.22386 9 6.5 9H17.5C17.7761 9 18 9.22386 18 9.5C18 9.77614 17.7761 10 17.5 10H6.5C6.22386 10 6 9.77614 6 9.5Z" />
        <path d="M10.5 13C10.2239 13 10 13.2239 10 13.5C10 13.7761 10.2239 14 10.5 14H17.5C17.7761 14 18 13.7761 18 13.5C18 13.2239 17.7761 13 17.5 13H10.5Z" />
        <path d="M8 13.5C8 14.0523 7.55228 14.5 7 14.5C6.44772 14.5 6 14.0523 6 13.5C6 12.9477 6.44772 12.5 7 12.5C7.55228 12.5 8 12.9477 8 13.5Z" />
        <path d="M3 10.5C3.55228 10.5 4 10.0523 4 9.5C4 8.94772 3.55228 8.5 3 8.5C2.44772 8.5 2 8.94772 2 9.5C2 10.0523 2.44772 10.5 3 10.5Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M3.25 6.5C3.94036 6.5 4.5 5.94036 4.5 5.25C4.5 4.55964 3.94036 4 3.25 4C2.55964 4 2 4.55964 2 5.25C2 5.94036 2.55964 6.5 3.25 6.5Z" />
        <path d="M7 5.25C7 4.83579 7.33579 4.5 7.75 4.5H17.25C17.6642 4.5 18 4.83579 18 5.25C18 5.66421 17.6642 6 17.25 6H7.75C7.33579 6 7 5.66421 7 5.25Z" />
        <path d="M7.75 9C7.33579 9 7 9.33579 7 9.75C7 10.1642 7.33579 10.5 7.75 10.5H17.25C17.6642 10.5 18 10.1642 18 9.75C18 9.33579 17.6642 9 17.25 9H7.75Z" />
        <path d="M11.75 13.5C11.3358 13.5 11 13.8358 11 14.25C11 14.6642 11.3358 15 11.75 15H17.25C17.6642 15 18 14.6642 18 14.25C18 13.8358 17.6642 13.5 17.25 13.5H11.75Z" />
        <path d="M4.5 9.75C4.5 10.4404 3.94036 11 3.25 11C2.55964 11 2 10.4404 2 9.75C2 9.05964 2.55964 8.5 3.25 8.5C3.94036 8.5 4.5 9.05964 4.5 9.75Z" />
        <path d="M7.25 15.5C7.94036 15.5 8.5 14.9404 8.5 14.25C8.5 13.5596 7.94036 13 7.25 13C6.55964 13 6 13.5596 6 14.25C6 14.9404 6.55964 15.5 7.25 15.5Z" />
      </g>
    </svg>
  ),
  displayName: 'TextBulletListTreeIcon',
});
