import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const BulletsIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svgFlippingInRtl}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M3 6.5C3.55228 6.5 4 6.05228 4 5.5C4 4.94772 3.55228 4.5 3 4.5C2.44772 4.5 2 4.94772 2 5.5C2 6.05228 2.44772 6.5 3 6.5ZM6 5.5C6 5.22386 6.22386 5 6.5 5H17.5C17.7761 5 18 5.22386 18 5.5C18 5.77614 17.7761 6 17.5 6H6.5C6.22386 6 6 5.77614 6 5.5ZM6 10.5C6 10.2239 6.22386 10 6.5 10H17.5C17.7761 10 18 10.2239 18 10.5C18 10.7761 17.7761 11 17.5 11H6.5C6.22386 11 6 10.7761 6 10.5ZM6.5 15C6.22386 15 6 15.2239 6 15.5C6 15.7761 6.22386 16 6.5 16H17.5C17.7761 16 18 15.7761 18 15.5C18 15.2239 17.7761 15 17.5 15H6.5ZM4 15.5C4 16.0523 3.55228 16.5 3 16.5C2.44772 16.5 2 16.0523 2 15.5C2 14.9477 2.44772 14.5 3 14.5C3.55228 14.5 4 14.9477 4 15.5ZM3 11.5C3.55228 11.5 4 11.0523 4 10.5C4 9.94772 3.55228 9.5 3 9.5C2.44772 9.5 2 9.94772 2 10.5C2 11.0523 2.44772 11.5 3 11.5Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M3.25 7C3.94036 7 4.5 6.44036 4.5 5.75C4.5 5.05964 3.94036 4.5 3.25 4.5C2.55964 4.5 2 5.05964 2 5.75C2 6.44036 2.55964 7 3.25 7ZM7 5.75C7 5.33579 7.33579 5 7.75 5H17.25C17.6642 5 18 5.33579 18 5.75C18 6.16421 17.6642 6.5 17.25 6.5H7.75C7.33579 6.5 7 6.16421 7 5.75ZM7.75 10C7.33579 10 7 10.3358 7 10.75C7 11.1642 7.33579 11.5 7.75 11.5H17.25C17.6642 11.5 18 11.1642 18 10.75C18 10.3358 17.6642 10 17.25 10H7.75ZM7.75 15C7.33579 15 7 15.3358 7 15.75C7 16.1642 7.33579 16.5 7.75 16.5H17.25C17.6642 16.5 18 16.1642 18 15.75C18 15.3358 17.6642 15 17.25 15H7.75ZM4.5 10.75C4.5 11.4404 3.94036 12 3.25 12C2.55964 12 2 11.4404 2 10.75C2 10.0596 2.55964 9.5 3.25 9.5C3.94036 9.5 4.5 10.0596 4.5 10.75ZM3.25 17C3.94036 17 4.5 16.4404 4.5 15.75C4.5 15.0596 3.94036 14.5 3.25 14.5C2.55964 14.5 2 15.0596 2 15.75C2 16.4404 2.55964 17 3.25 17Z"
      />
    </svg>
  ),
  displayName: 'BulletsIcon',
});
