import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const TwitterIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      role="presentation"
      focusable="false"
      viewBox="2 2 16 16"
      className={classes.svg}
      style={{ overflow: 'visible' }}
    >
      <path
        d="M10.0003 18.3337C14.6027 18.3337 18.3337 14.6027 18.3337 10.0003C18.3337 5.39795 14.6027 1.66699 10.0003 1.66699C5.39795 1.66699 1.66699 5.39795 1.66699 10.0003C1.66699 14.6027 5.39795 18.3337 10.0003 18.3337Z"
        fill="#1B9DF0"
      />
      <path
        d="M8.47513 14.3958C12.171 14.3958 14.1918 11.3333 14.1918 8.67917C14.1918 8.59167 14.1918 8.50417 14.1876 8.42083C14.5793 8.1375 14.921 7.78333 15.1918 7.37917C14.8335 7.5375 14.446 7.64583 14.0376 7.69583C14.4543 7.44583 14.771 7.05417 14.921 6.58333C14.5335 6.8125 14.1043 6.97917 13.646 7.07083C13.2793 6.67917 12.7585 6.4375 12.1793 6.4375C11.071 6.4375 10.171 7.3375 10.171 8.44583C10.171 8.60417 10.1876 8.75833 10.2251 8.90417C8.55429 8.82083 7.07513 8.02083 6.08346 6.80417C5.91263 7.1 5.81263 7.44583 5.81263 7.8125C5.81263 8.50833 6.1668 9.125 6.70846 9.48333C6.3793 9.475 6.07096 9.38333 5.80013 9.23333C5.80013 9.24167 5.80013 9.25 5.80013 9.25833C5.80013 10.2333 6.4918 11.0417 7.41263 11.2292C7.24596 11.275 7.0668 11.3 6.88346 11.3C6.7543 11.3 6.6293 11.2875 6.5043 11.2625C6.75846 12.0625 7.50013 12.6417 8.37929 12.6583C7.69179 13.1958 6.82513 13.5167 5.88346 13.5167C5.72096 13.5167 5.56263 13.5083 5.4043 13.4875C6.28346 14.0625 7.34179 14.3958 8.47513 14.3958Z"
        fill="white"
      />
    </svg>
  ),
  displayName: 'TwitterIcon',
});
