import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const AppFolderIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M2 4.5C2 3.11929 3.11929 2 4.5 2H15.5C16.8807 2 18 3.11929 18 4.5V15.5C18 16.8807 16.8807 18 15.5 18H4.5C3.11929 18 2 16.8807 2 15.5V4.5ZM4.5 3C3.67157 3 3 3.67157 3 4.5V15.5C3 16.3284 3.67157 17 4.5 17H15.5C16.3284 17 17 16.3284 17 15.5V4.5C17 3.67157 16.3284 3 15.5 3H4.5ZM5 6C5 5.44772 5.44772 5 6 5H8C8.55228 5 9 5.44772 9 6V8C9 8.55228 8.55228 9 8 9H6C5.44772 9 5 8.55228 5 8V6ZM8 6H6V8H8V6ZM5 12C5 11.4477 5.44772 11 6 11H8C8.55228 11 9 11.4477 9 12V14C9 14.5523 8.55228 15 8 15H6C5.44772 15 5 14.5523 5 14V12ZM8 12H6V14H8V12ZM11 6C11 5.44772 11.4477 5 12 5H14C14.5523 5 15 5.44772 15 6V8C15 8.55228 14.5523 9 14 9H12C11.4477 9 11 8.55228 11 8V6ZM14 6H12V8H14V6ZM11 12C11 11.4477 11.4477 11 12 11H14C14.5523 11 15 11.4477 15 12V14C15 14.5523 14.5523 15 14 15H12C11.4477 15 11 14.5523 11 14V12ZM14 12H12V14H14V12Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M2 4.5C2 3.11929 3.11929 2 4.5 2H15.5C16.8807 2 18 3.11929 18 4.5V15.5C18 16.8807 16.8807 18 15.5 18H4.5C3.11929 18 2 16.8807 2 15.5V4.5ZM4.5 3C3.67157 3 3 3.67157 3 4.5V15.5C3 16.3284 3.67157 17 4.5 17H15.5C16.3284 17 17 16.3284 17 15.5V4.5C17 3.67157 16.3284 3 15.5 3H4.5ZM11 12C11 11.4477 11.4477 11 12 11H14C14.5523 11 15 11.4477 15 12V14C15 14.5523 14.5523 15 14 15H12C11.4477 15 11 14.5523 11 14V12ZM11 6C11 5.44772 11.4477 5 12 5H14C14.5523 5 15 5.44772 15 6V8C15 8.55228 14.5523 9 14 9H12C11.4477 9 11 8.55228 11 8V6ZM5 12C5 11.4477 5.44772 11 6 11H8C8.55228 11 9 11.4477 9 12V14C9 14.5523 8.55228 15 8 15H6C5.44772 15 5 14.5523 5 14V12ZM5 6C5 5.44772 5.44772 5 6 5H8C8.55228 5 9 5.44772 9 6V8C9 8.55228 8.55228 9 8 9H6C5.44772 9 5 8.55228 5 8V6Z"
      />
    </svg>
  ),
  displayName: 'AppFolderIcon',
});
