import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const PageFitIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg viewBox="2 2 16 16" role="presentation" className={classes.svg}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M5.5 6C4.67157 6 4 6.67157 4 7.5V8.5C4 8.77614 4.22386 9 4.5 9C4.77614 9 5 8.77614 5 8.5V7.5C5 7.22386 5.22386 7 5.5 7H6.5C6.77614 7 7 6.77614 7 6.5C7 6.22386 6.77614 6 6.5 6H5.5ZM13.5 6C13.2239 6 13 6.22386 13 6.5C13 6.77614 13.2239 7 13.5 7H14.5C14.7761 7 15 7.22386 15 7.5V8.5C15 8.77614 15.2239 9 15.5 9C15.7761 9 16 8.77614 16 8.5V7.5C16 6.67157 15.3284 6 14.5 6H13.5ZM5 11.5C5 11.2239 4.77614 11 4.5 11C4.22386 11 4 11.2239 4 11.5V12.5C4 13.3284 4.67157 14 5.5 14H6.5C6.77614 14 7 13.7761 7 13.5C7 13.2239 6.77614 13 6.5 13H5.5C5.22386 13 5 12.7761 5 12.5V11.5ZM16 11.5C16 11.2239 15.7761 11 15.5 11C15.2239 11 15 11.2239 15 11.5V12.5C15 12.7761 14.7761 13 14.5 13H13.5C13.2239 13 13 13.2239 13 13.5C13 13.7761 13.2239 14 13.5 14H14.5C15.3284 14 16 13.3284 16 12.5V11.5ZM2 6C2 4.89543 2.89543 4 4 4H16C17.1046 4 18 4.89543 18 6V14C18 15.1046 17.1046 16 16 16H4C2.89543 16 2 15.1046 2 14V6ZM3 6V14C3 14.5523 3.44772 15 4 15H16C16.5523 15 17 14.5523 17 14V6C17 5.44772 16.5523 5 16 5H4C3.44772 5 3 5.44772 3 6ZM5.5 6C4.67157 6 4 6.67157 4 7.5V8.5C4 8.77614 4.22386 9 4.5 9C4.77614 9 5 8.77614 5 8.5V7.5C5 7.22386 5.22386 7 5.5 7H6.5C6.77614 7 7 6.77614 7 6.5C7 6.22386 6.77614 6 6.5 6H5.5ZM5 11.5C5 11.2239 4.77614 11 4.5 11C4.22386 11 4 11.2239 4 11.5V12.5C4 13.3284 4.67157 14 5.5 14H6.5C6.77614 14 7 13.7761 7 13.5C7 13.2239 6.77614 13 6.5 13H5.5C5.22386 13 5 12.7761 5 12.5V11.5Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M4 4C2.89543 4 2 4.89543 2 6V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V6C18 4.89543 17.1046 4 16 4H4ZM5.5 6H6.5C6.77614 6 7 6.22386 7 6.5C7 6.77614 6.77614 7 6.5 7H5.5C5.22386 7 5 7.22386 5 7.5V8.5C5 8.77614 4.77614 9 4.5 9C4.22386 9 4 8.77614 4 8.5V7.5C4 6.67157 4.67157 6 5.5 6ZM13.5 6H14.5C15.3284 6 16 6.67157 16 7.5V8.5C16 8.77614 15.7761 9 15.5 9C15.2239 9 15 8.77614 15 8.5V7.5C15 7.22386 14.7761 7 14.5 7H13.5C13.2239 7 13 6.77614 13 6.5C13 6.22386 13.2239 6 13.5 6ZM5 11.5V12.5C5 12.7761 5.22386 13 5.5 13H6.5C6.77614 13 7 13.2239 7 13.5C7 13.7761 6.77614 14 6.5 14H5.5C4.67157 14 4 13.3284 4 12.5V11.5C4 11.2239 4.22386 11 4.5 11C4.77614 11 5 11.2239 5 11.5ZM16 11.5V12.5C16 13.3284 15.3284 14 14.5 14H13.5C13.2239 14 13 13.7761 13 13.5C13 13.2239 13.2239 13 13.5 13H14.5C14.7761 13 15 12.7761 15 12.5V11.5C15 11.2239 15.2239 11 15.5 11C15.7761 11 16 11.2239 16 11.5Z"
      />
    </svg>
  ),
  displayName: 'PageFitIcon',
});
